<template>
    <v-dialog v-model="dialog" scrollable persistent max-width="600px" transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                    <v-icon
                        small
                        class="mr-2"
                        v-on="{ ...tooltip, ...on }"
                        @click="getDataEstrella()"
                    >mdi-pencil</v-icon>
                </template>
                <span>Modificar</span>
            </v-tooltip>
        </template>
        <v-card :loading="loading"> 
            <v-card-title class="pa-0">
              <ModalTitle :titulo="titulo" @cancelar="cancelar"/>
            </v-card-title>  
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-text-field 
                            v-model="promocion.interesado"
                            label="Interesado"
                            :error-messages="interesadoErrors"
                            @input="$v.promocion.interesado.$touch()"
                            @blur="$v.promocion.interesado.$touch()"
                            counter="100"
                            required>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            v-model="categoria"
                            :hint="categoriaHint"
                            :label="categoriaLabel"
                            :error-messages="categoriaErrors"
                            @input="$v.categoria.$touch()"
                            @blur="$v.categoria.$touch()"
                            counter="100"
                            required>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea
                            label="Descripción de Actividad"
                            v-model="promocion.descripcionActividad"
                            outlined
                            counter="500"
                            :error-messages="descripcionErrors"             
                            @change="$v.promocion.descripcionActividad.$touch()"
                            @blur="$v.promocion.descripcionActividad.$touch()"
                            required>
                        </v-textarea>  
                    </v-col>
                    <v-col cols="12">
                        <v-menu
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="fechaMoment"
                                    label="Fecha de Actividad"
                                    prepend-inner-icon="mdi-calendar-month"
                                    readonly
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker 
                                v-model="fechaActividad" 
                                locale="es-CR" 
                                @input="menu = false">
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            label="Categoría PBAE"
                            v-model="promocion.categoriaPbae"
                            :error-messages="categoriaPbaeErrors"             
                            @change="$v.promocion.categoriaPbae.$touch()"
                            @blur="$v.promocion.categoriaPbae.$touch()"
                            counter="100">
                        </v-text-field>  
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            label="Número de Registro"
                            v-model="promocion.numeroRegistro"
                            :error-messages="numeroRegistroErrors"             
                            @change="$v.promocion.numeroRegistro.$touch()"
                            @blur="$v.promocion.numeroRegistro.$touch()">
                        </v-text-field>  
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0" v-if="tipo==3">
                        <v-select
                            label="Relación con la Organización"
                            :items="participantes"
                            item-text="nombre"
                            item-value="id"
                            multiple
                            chips
                            v-model="listParticipantes"
                            :error-messages="ParticipanteErrors"             
                            @change="$v.listParticipantes.$touch()"
                            @blur="$v.listParticipantes.$touch()"
                            required
                        ></v-select>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text :disabled="loading" color="error" @click="cancelar">Cancelar</v-btn>
                <v-btn text :loading="loading" color="#0B3F67" @click="modificarEstrella">Modificar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import moment from "moment"
import axios from "@/axios";
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required, requiredIf, integer, maxLength } from "vuelidate/lib/validators";

export default {
    created() {
        axios.defaults.headers.common["Authorization"] = "Bearer " + this.$store.getters.token;
    },
    components: {
      ModalTitle
    },
    computed: {
        fechaMoment () {
            return this.fechaActividad ? moment(this.fechaActividad).format('DD/MM/YYYY') : '';
        },
        idUsuario() {
            return this.$store.getters.usuario.id;
        },
        interesadoErrors() {
            const errors = []
            if (!this.$v.promocion.interesado.$dirty) return errors            
            !this.$v.promocion.interesado.required && errors.push('El nombre del ente interesado es requerido.')
            !this.$v.promocion.interesado.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        categoriaErrors() {
            const errors = []
            if (!this.$v.categoria.$dirty) return errors            
            !this.$v.categoria.required && errors.push('La categoría es requerida.')
            !this.$v.categoria.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        descripcionErrors() {
            const errors = []
            if (!this.$v.promocion.descripcionActividad.$dirty) return errors            
            !this.$v.promocion.descripcionActividad.required && errors.push('La descripción de la actividad es requerida.')
            !this.$v.promocion.descripcionActividad.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        categoriaPbaeErrors() {
            const errors = []
            if (!this.$v.promocion.categoriaPbae.$dirty) return errors            
            !this.$v.promocion.categoriaPbae.required && errors.push('La categoría del PBAE es requerida.')
            !this.$v.promocion.categoriaPbae.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        numeroRegistroErrors() {
            const errors = []
            if (!this.$v.promocion.numeroRegistro.$dirty) return errors            
            !this.$v.promocion.numeroRegistro.required && errors.push('El número de registro es requerido.')
            !this.$v.promocion.numeroRegistro.integer && errors.push('Solo debe digitar números.')
            return errors
        },
        ParticipanteErrors () {
            const errors = []
            if (!this.$v.listParticipantes.$dirty) return errors
            !this.$v.listParticipantes.required && errors.push('Campo requerido')
            return errors
        },
        categoriaLabel() {
            return this.tipo === 3 ? 'Categoría Cadena de Valor' : 'Categoría Comunitaria';
        },
        tiempoPrograma() {
            return this.$store.getters.sucursal.tiempoEnPrograma;
        },
        categoriaHint() {
            return this.tipo === 3 ? 
                'Ej. Clientes, Proveedores' : 
                'Ej. Centros Educativos, Asociaciones de Desarrollo';
        },
    },
    props: [
        'tipo',
        'titulo',
        'id',
    ],
    data: () => ({
        dialog: false,
        loading: false,
        menu: false,
        promocion: {
            interesado: '',
            descripcionActividad: '',
            categoriaPbae: '',
            numeroRegistro: '',
        },
        fechaActividad: new Date().toISOString().substr(0, 10),
        categoria: '',
        obtuvoGalardon: false,
        listParticipantes: [],
        participantes: [],
    }),
    validations: {
        promocion: {
            interesado: { required, maxLength: maxLength(100) },
            descripcionActividad: { required, maxLength: maxLength(500) },
            categoriaPbae: { maxLength: maxLength(100),required: requiredIf(function() { 
                return this.tiempoPrograma >= 2;
            })},
            numeroRegistro: { required: requiredIf(function() { 
                return this.tiempoPrograma >= 2; 
            }), integer},
        },
        categoria: { required, maxLength: maxLength(100) },
        listParticipantes: {required: requiredIf(function(){
            return this.tipo == 3;
        })},
    },
    methods: {
        dateFormat(fecha) {
            return new Date(fecha).toISOString().substr(0, 10);
        },
        cancelar() {
            this.promocion = {};
            this.categoria = '';
            this.fechaActividad = new Date().toISOString().substr(0, 10);
            this.dialog = false;
            this.loading = false;
            this.$v.promocion.$reset();
            this.$v.categoria.$reset();
        },
        getDataEstrella() {
            this.loading = true;
            axios.get("/estrellas/getPromocion/"+this.id + '/' + this.tipo)
            .then(response => {
                this.promocion = response.data.promocion;   
                this.fechaActividad = this.dateFormat(response.data.promocion.fechaActividad);
                if (this.tipo == 3)
                    this.categoria = response.data.promocion.categoriaCadena;
                else
                    this.categoria = response.data.promocion.categoriaComunidad;                 
                this.loading = false;
                this.participantes = response.data.participantes;
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
                this.loading = false;
              }
            })
        },
        modificarEstrella() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.loading = true;
                if (this.tipo === 3)
                    this.modificaPromoCadena();
                else
                    this.modificaPromoComunidad();
            }
        },
        modificaPromoCadena() {
            let promo = this.promocion;
            delete promo.docAsistencia;
            delete promo.docActividad;
            delete promo.docEvidencia;
            axios.put("/estrellas/modificarPromoCadenaValor", promo, { params: {
                idUsuario: this.idUsuario,
                categoria: this.categoria,
                fecha: this.fechaActividad,
                participantes: this.listParticipantes.toString()
            }}).then(response => {
                this.$emit("recargaTabla");
                this.$store.dispatch("noti", { mensaje: "Promoción PBAE - Cadena de Valor modificada", color: "success" });
                this.cancelar();
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log("Error al modificar la promoción " + error);
                this.$store.dispatch("noti", { 
                    mensaje: "Ocurrió un error no se modificó la promoción PBAE- Cadena de Valor",
                    color: "error"
                });
                this.cancelar();
              }
            });
        },
        modificaPromoComunidad() {
            let promo = this.promocion;
            delete promo.docEvidencia;
            axios.put("/estrellas/modificarPromoComunidad", promo, { params: {
                idUsuario: this.idUsuario,
                categoria: this.categoria,
                fecha: this.fechaActividad,
            }}).then(response => {
                this.efectivo();
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                this.erroneo(error);
              }
            });
        },
        efectivo() {
            this.$emit("recargaTabla");
            this.$store.dispatch("noti", { mensaje: "Promoción PBAE modificada", color: "success" });
            this.cancelar();
        }, 
        erroneo(error) {
            console.log("Error al modificar promoción " + error);
            this.$store.dispatch("noti", { 
                mensaje: "Ocurrió un error no se modificó la promoción PBAE",
                color: "error"
            });
            this.cancelar();
        },
    }
};
</script>
